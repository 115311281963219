
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},

  setup(props, { emit }) {
    debugger;
    const store = useStore();
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addRFQmodel = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const supplier_data = ref<Array<Supplier>>([]);
    const consignee_data = ref<Array<Supplier>>([]);
    const branch_data = ref([]);
    const rfq_data = ref([]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<Product[]>([]);
    const dialogSelectProductTableVisible = ref(false);
    const productList = ref<Product[]>([]);
    const myData = ref<Array<Product>>([]);
    const tableData = ref<Array<Product>>([]);
    const uom_data = ref<Array<UOM>>([]);
    const create_data = ref<any>({});

    interface UOM {
      uom_id: number;
      uom_display_name: string;
    }

    const is_consignee_data = ref([
      { id: 1, name: "Yes" },
      { id: 2, name: "No" },
    ]);

    interface Product {
      stock_quotation_offer_id: string;
      product_name: string;
      offered_date: string;
      offered_qty: string;
      uom: string;
      offered_rate: string;
      offer_valid_date: string;
      payment_term: string;
      incoterm: string;
      delivery_term: string;
      offer_remark: string;
    }
    const router = useRouter();

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    getAddData();
    getUOM();

    const formData = ref({
      is_consignee: 2,
      supplier_select: "",
      supplier_branch_select: "",
      rfq_select: "",
      sjvd_date: new Date(),
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs("New Purchase Contract", []);
      getCreateData();
    });

    const rules = ref({
      sjvd_date: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());

      debugger;
      let product_list: any = [];
      create_data.value.offers_list.map((row) => {
        row.offer_data.map((item) => {
          if (item.ordered_qty && parseFloat(item.ordered_qty) > 0) {
            product_list.push({
              quotation_offer_id: item.quotation_offer_id,
              order_quantity: item.ordered_qty
                ? parseFloat(item.ordered_qty).toFixed(3)
                : "",
            });
          }
        });
      });
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        user_id: user.user_id,
        purchase_contract_date: data.sjvd_date,
        purchase_quotation_id: props.id,
        contract_data: product_list,
      };
      console.log(JSON.stringify(db_data));
      try {
        await store.dispatch(
          ActionsFi.CUST_CREATE_PURCHASE_CONTRACT,
          db_data
        )
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-purchase-contract-listing" });
                emit("refreshList");
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getWarehouseList() {
      let values = {
        company_id: formData.value["supplier_select"],
      };
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          branch_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getRFQList() {
      let params = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_branch_select"],
      };
      await store
        .dispatch(
          ActionsFi.CUST_GET_PENDING_QUOTATION_REQUEST_BY_CLIENT_LIST,
          params
        )
        .then(({ data }) => {
          debugger;
          rfq_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val: Product[]) => {
      multipleSelection.value = val;
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogSelectProductTableVisible.value = false;
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTableData() {
      let params = {
        stock_quotation_request_id: formData.value["rfq_select"],
      };
      debugger;
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_RFQ_OFFER_LIST, params)
        .then(({ data }) => {
          debugger;
          data = data.map((product) => {
            let value = {
              stock_quotation_offer_id: product.stock_quotation_offer_id,
              product_name: product.product_name,
              offered_date: product.offered_date,
              offered_qty: product.offered_qty + " " + product.uom,
              uom: product.uom,
              offered_rate: product.offered_rate,
              offer_valid_date: product.offer_valid_date,
              payment_term: product.payment_term,
              incoterm: product.incoterm,
              delivery_term: product.delivery_term,
              offer_remark: product.offer_remark,
            };

            return value;
          });
          tableData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDilogue = () => {
      if (formData.value["type_select"] === "") {
        Swal.fire({
          text: "Please select purchase requisition type first!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        dialogSelectProductTableVisible.value = true;
      }
    };

    const onContinue = () => {
      debugger;
      if (multipleSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one user",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        productList.value = multipleSelection.value;
        myData.value = multipleSelection.value;
        dialogSelectProductTableVisible.value = false;
      }
    };
    async function getCreateData() {
      let params = {
        purchase_quotation_id: props.id,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PURCHASE_CONTRACT_CREATE_DATA, params)
        .then(({ data }) => {
          debugger;
          data?.offers_list.map((row) => {
            row?.offer_data.map((item) => {
              item.ordered_qty = "";
            });
          });
          create_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      formRef,
      loading,
      addRFQmodel,
      supplier_data,
      getCompanyList,
      is_consignee_data,
      consignee_data,
      branch_data,
      rfq_data,
      getWarehouseList,
      dialogSelectProductTableVisible,
      tableData,
      myData,
      multipleTableRef,
      multipleSelection,
      handleSelectionChange,
      onContinue,
      onCancle,
      getTableData,
      openDilogue,
      getRFQList,
      getCreateData,
      create_data,
    };
  },
});
